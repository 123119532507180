<template>
  <div class="az_abord_con">
    <div v-if="!system.isPhone">
      <!-- 顶部菜单栏 -->
      <top-menu-bar />
      <!-- 顶部说明 -->
      <top-intr @goApply="goApply" v-if="$route.query.prev != 'az'" />
      <top-intr-az @goApply="goApply" v-if="$route.query.prev == 'az'" />
      <!-- 下载 -->
      <dowload v-if="$route.query.prev != 'az'" />

      <!-- 审核流程 -->
      <apply-steps v-if="$route.query.prev != 'az'" />

      <!-- 平台介绍 -->
      <platform-introduction
        v-if="$route.query.prev == 'az'"
      ></platform-introduction>

      <!-- 我们的优势 -->
      <!-- <div class="fontfamilyff1 font36 we_leng">{{ $t("erweimaDowlodeData.text1") }}</div> -->
      <div class="font36 we_leng font500w">
        {{ $t("erweimaDowlodeData.text1") }}
      </div>
      <our-goodness />
      <!-- 底部 -->
      <div ref="content" class="content">
        <bottom-det id="6" />
      </div>
    </div>
    <!-- 
      wap端
     -->
    <div v-if="system.isPhone">
      <!-- 顶部菜单栏 -->
      <bar-wap class="wap_bar_menu" />
      <!-- 顶部说明 -->
      <wap-top-intr @goApply="goApply" v-if="$route.query.prev != 'az'" />
      <wap-top-intr-az @goApply="goApply" v-if="$route.query.prev == 'az'" />
      <!-- 下载 -->
      <wap-dowload v-if="$route.query.prev != 'az'" />
      <!-- 审核流程 -->
      <wap-apply-steps v-if="$route.query.prev != 'az'" />

      <!-- 平台介绍 -->
      <wap-platform-introduction v-if="$route.query.prev == 'az'" />

      <!-- 我们的优势 -->
      <!-- <div class="fontfamilyff1 font36 we_leng">{{ $t("erweimaDowlodeData.text1") }}</div> -->
      <wap-our-goodness />
      <!-- 底部 -->
      <wap-bottom />
    </div>
  </div>
</template>
<script>
import TopMenuBar from "@/views/publicComponents/TopMenuBar";
import BarWap from "@/views/publicComponents/BarWap";
import BottomDet from "@/views/publicComponents/BottomDet";
import WapBottom from "@/views/publicComponents/WapBottom";
const TopIntr = () => import("./components/TopIntr").then(m => m.default || m);
const TopIntrAz = () =>
  import("./components/TopIntrAz").then(m => m.default || m);
const WapTopIntr = () =>
  import("./components/WapTopIntr").then(m => m.default || m);
const WapTopIntrAz = () =>
  import("./components/WapTopIntrAz").then(m => m.default || m);
const ApplySteps = () =>
  import("./components/ApplySteps").then(m => m.default || m);
const PlatformIntroduction = () =>
  import("./components/PlatformIntroduction").then(m => m.default || m);
const WapPlatformIntroduction = () =>
  import("./components/WapPlatformIntroduction").then(m => m.default || m);
const WapApplySteps = () =>
  import("./components/WapApplySteps").then(m => m.default || m);
const OurGoodness = () =>
  import("./components/OurGoodness").then(m => m.default || m);
const WapOurGoodness = () =>
  import("./components/WapOurGoodness").then(m => m.default || m);
const Dowload = () => import("./components/Dowload").then(m => m.default || m);
const WapDowload = () =>
  import("./components/WapDowload").then(m => m.default || m);
export default {
  name: "AzAbord",
  components: {
    TopMenuBar,
    TopIntrAz,
    BarWap,
    BottomDet,
    WapBottom,
    TopIntr,
    PlatformIntroduction,
    WapPlatformIntroduction,
    ApplySteps,
    OurGoodness,
    Dowload,
    WapTopIntr,
    WapTopIntrAz,
    WapApplySteps,
    WapOurGoodness,
    WapDowload
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    goApply(applyQuery) {
      this.$router.push(applyQuery);
    }
  }
};
</script>
<style lang="less" scoped>
.az_abord_con {
  background: #f8f8f8;
}
.wap_bar_menu {
  height: calc(100vw * 4.8 / 37.5);
}
.content {
  padding-top: 0;
  background: #fff;
  // border-top: 4px dashed #bf9264;
}
.we_leng {
  margin-top: calc(100vw * 10 / 192);
}
</style>
